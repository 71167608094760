
.body{
    width: 100%;
    height:calc(100% - 10px);
    /* background-color: greenyellow; */
    position: relative;
    /* overflow-x: auto; */
}
.headline{
    width: 100%;
    height: 50px;
    padding: 0;
    margin: 0;
    
}
.title{
        font-size: 30px;
    font-weight: 100;
}
.headline hr{
    padding: 0;
    margin: 0;
}
.fromtit{
    height: 50px;
    width: 100%;
    background-color: antiquewhite;
}
.fromt{
    /* height: 100%; */
    overflow: hidden;
}
.ant-table-header{
    height: 54.9px;
}
.ant-table-body{
    overflow-y: auto !important;
}
.xuanx{
    display: flex;
    align-items:center;
    font-size: 15px;
    margin-bottom: 10px;
}
.xuanx input{
    width:90% !important;
}
.inps>:first-child{
    width:90% !important;
}
.departmentHeight{
 max-height: 576px;
 overflow-y: auto;
 overflow-x: hidden;   
}

.departmentHeight::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
    position: fixed;
    left: -10px;
    background-color: rgb(237, 237, 237);
  }
  .departmentHeight::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 20px;
    box-shadow: inset 0 0 5px rgba(138, 138, 138, 0.2);
    background: #a7a7a7;
    position: absolute;
    /* left: -10px;
    margin: -10px;
    padding: 10px; */
  }
  .departmentHeight::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    /* box-shadow: inset 0 0 5px #EDEDED; */
    border-radius: 10px;
    /* background: #EDEDED; */
  }
 .modal_foot .ant-modal-footer{
    border-top:none !important;
    padding: 0 !important;
  }
  .modal_foot{
    min-width: 700px;
  }
  