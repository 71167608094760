#components-layout-demo-custom-trigger .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
  }
  
 .trigger:hover {
    color: #1890ff;
  }
 .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
  }
  
  .site-layout .site-layout-background {
    background: #fff;
  }
  .bttit{
    font-size: 20px;
    text-align: center;
    color: #fff;
    font-weight: bold;
}
.trigger>:first-child{
width: 2em;
height: 2em;
}
.trigger{
  margin-left:10px;
  /* margin-top: 17px; */
}
.site-layout-background{
position: relative;
display: flex;
justify-content: space-between;
align-items: center;
}
.portraits{
  width:150px;
  height: 50px;
  /* background-color: #1890ff; */
  margin-right: 15px;
  display: flex;
  align-items: center;
}
.portrait{
height: 40px;
width: 40px;
border-radius: 50%;
background-color: rgb(53, 134, 241);
/* line-height: 40px; */
color: white;
text-align: center;
font-size: 10px;
position: relative;
}
.portrait img{
  width: auto;

  height: auto;
  
  max-width: 70%;
  /* position: absolute; */
  max-height: 70%;
  /* line-height: 2.84rem; */
  
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);

}
.tcbtn{
  color: rgb(201, 119, 119);
  margin-left: 15px;
  cursor:pointer
}
.tcbtn:hover {
  color: red;
}
