.phone{
    background-image: url('../../../../assets/bg.png');
    background-repeat:no-repeat;
background-size: cover;
background-position: center center;
  width:15rem;
  height: 26.68rem;
  background-color: rgba(220, 214, 214, 0.5);
  position: absolute;
/* z-index: 2; */
top: 4rem;
right: 15rem;
border-radius: 1rem;
overflow: hidden;
}

.mengcs{
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
}
.zs-box{
    position: absolute;
    width: 100%;
    height: 19rem;
    /* background-color: rgb(183, 91, 91); */
    transform: translate(-50%,-50%);
    top: 45%;
    left: 50%;
}
.zs-border{
    position: absolute;
    /* top: 1.6rem; */
    left: calc(50% - 6.38rem);
    /* transform: translate(-50%); */
    width: 12.76rem;
    height: 19.2rem;
}
.zs-openers{
    position: absolute;
    top: 0rem;
    left: 0;
    background-color: aliceblue;
    width: 12.76rem;
    height: 16.8rem;
    overflow: hidden;
}
.zs-bgOPeners{
    width: 100%;
    height: 100%;
}
@font-face {
    font-family: nameH;
    src: url('https://res.xiaoxxx.com/test/m.xiaoxxx.com/202206105120d7a22bebfdd5666393.otf'),url('https://res.xiaoxxx.com/test/m.xiaoxxx.com/202206105228322df373c779393103.ttf');
    /* src: url('https://res.xiaoxxx.com/test/m.xiaoxxx.com/202206105228322df373c779393103.ttf'); */
    }
    @font-face {
    font-family: nameM;
    src: url('https://res.xiaoxxx.com/test/m.xiaoxxx.com/20220610a14318d3ce1ca9cb911958.otf'),url('https://res.xiaoxxx.com/test/m.xiaoxxx.com/202206103979c5b6581c95ba453902.ttf');
    
    /* src: url('https://res.xiaoxxx.com/test/m.xiaoxxx.com/202206103979c5b6581c95ba453902.ttf'); */
    }
    .t1-family{
        /* font-family: nameH !important; */
        font-family: nameM ;
      }
      .t2-family{
        font-family: nameH !important;
        /* font-family: nameM !important; */
      }
      .t3-family{
        /* font-family: nameH !important; */
        font-family: nameM !important;
      }
      .t4-family{
        font-family: nameH !important;
        /* font-family: nameM !important; */
      }
      .t1-opener{
        position: absolute;
        top: 1.6rem;
        width: 11rem;
        left: calc(50% - 5.5rem);
        text-align: center;
        font-size: 0.8rem;
        /* font-weight: bold; */
        color: #2e54a6;
      }
.t2-opener{
    position: absolute;
    top: 4.1rem;
    width: 11rem;
    left: calc(50% - 5.5rem);
    /* background-color: #3471f3; */
    text-align: center;
    font-size: 1.2rem;
    /* font-weight: bold; */
    color: #3471f3;
}
.t3-opener{
    position: absolute;
    top: 7rem;
    width: 9.6rem;
    left: calc(50% - 4.8rem);
    text-align: justify;
    font-size: 0.6rem;
    /* font-weight: bold; */
    color: rgb(0, 0, 0);
}
.t4-opener{
    position: absolute;
    top: 9.6rem;
    width: 100%;
    text-align: center;
    font-size: 1.2rem;
    /* font-weight: bold; */
    color: #e57716;
}
.logo-operer{
    width: 4rem;
    position: absolute;
    /* top: 7rem; */
    bottom: 1.6rem;
    left: calc(50% - 2rem);
}
.zs-gbBtn{
    width: 1rem;
    position: absolute;
    bottom: -0.4rem;
    transform: translate(-50%);
    left:50%;
}