.bodysa .footers{
    padding: 0 !important;
}
.bodysa #footers{
    padding: 0 !important;
}
.bodysa .ant-modal-footer{
    padding: 0 !important;

}
.bodysa .imgs{
    height: 50px;
}