.bacc{
    background: url(../assets/2.jpg) no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    background-attachment: fixed;
    height: 100%;
  
    position: fixed;
    background-color: rgb(228, 228, 228); 
}
.obscuration{
    position: fixed;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    background-color: rgba(43, 44, 44, 0.7);
}
.input{
    /* z-index: 999; */
    position: fixed;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    height: 400px;
    box-shadow: 0px 0px 5px 2px rgb(152, 157, 158);
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 20px 20px;
}
.form{
    position: relative;
    left: -40px;
}
.icons{
    width: 60px;
    position: relative;
    left: 50%;
    transform: translate(-50%);
    margin-bottom: 20px;
    /* margin: auto 0; */
    /* text-align: ; */
}
.btn{
    /* position: relative; */
    /* right: -20px; */
    width: 110px;
    height: 40px;
    border-radius: 15px;
    margin-top:20px;
    position: relative;
    left: 45%;
    transform: translate(-50%);

}
.titles{
    position: relative;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    color: rgb(185, 205, 207);
    margin-bottom: 15px;
}
@media (max-width: 575px){
    .form{
        position: relative;
        left: 0px;
    }
    .btn{
        margin-top:5px;
        position: relative;
        left: 0;
        transform: translate(-0%);
    
    }
    .icons{

        margin-bottom: 10px;

    }
}