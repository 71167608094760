.bodys{
    width: 100%;
    height:100%;
    /* background-color: greenyellow; */
    position: relative;
    
    overflow-x: auto;
}
.ant-form-item{
margin-bottom: 10px;
}
.bgz{
    position: absolute;
    width: 100%;
    height: 26rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.phone{
      background-image: url('../../../assets/bg.png');
      background-repeat:no-repeat;
  background-size: cover;
  background-position: center center;
    width:15rem;
    height: 26.68rem;
    background-color: rgba(220, 214, 214, 0.5);
    position: absolute;
/* z-index: 2; */
top: 4rem;
right: 15rem;
border-radius: 1rem;
overflow: hidden;
}
.btn1{
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    top: 22rem;
    height: 2.58rem;
    /* width: ; */
}
.btn2{
    position: absolute;
    right: 0rem;
    width: 1.5rem;
    top: 1.6rem;
}
.btn3{
    position: absolute;
    right: 0rem;
    width: 1.5rem;
    top: 5.9rem;
}
.btn7{
  position: absolute;
  left: 0rem;
  width: 1.5rem;
  top: 5.5rem;
}
.btn8{

  position: absolute;
  left: 0rem;
   width: 1.5rem;
   top: 1.6rem;
}
.mengcs{
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.4);
}
.xmk {
    position: absolute;
    width: 12.2rem;
    left: 0;
    top: 2.4rem;
  }
  .bgzs {
    position: absolute;
    z-index: 10;
    width: 12.2rem;
    height: 12.7rem;
    /* background-color: aqua; */
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .xiaoren{
    width: 4rem;
    top: -0.4rem;
    left: 50%;
    transform: translate(-50%);
    position: absolute;
    z-index: 2;
  }
  .cjk{
    position: absolute;
   width: 12.16rem;
   height: 14.8rem;
   /* background-color: aqua; */
   top: 35%;
   left: 50%;
   transform: translate(-50%, -50%);
  }
  .defen{
    position: absolute;
    width: 12.16rem;
    top: 4.5rem;
    left: 0;
   }
   .xiaorens{
    width: 4rem;
    top: 1.6rem;
    left: 50%;
    transform: translate(-50%);
    position: absolute;
    z-index: 2;
  }
  .bgaz {
    position: absolute;
    width: 100%;
    /* width:6.8rem ; */
    left: 50%;
    height: 26rem;
    top: 50%;
    transform: translate(-50%, -50%);
    /* overflow: auto; */
  }
  .sphb{
    position: absolute;
    height: 23rem;
    top: 0.5rem;
    left: 50%;
    transform: translate(-50%);
  }
  .xiaor{
    position: absolute;
    width: 2.3rem;
    top: 0.5rem;
    left: 3.1rem;
  }