.inps>:first-child{
    width: 35%;
}
.bodys{
    width: 100%;
    height:100%;
    /* top: 10rem; */
    /* padding-top: 10px; */
    /* background-color: greenyellow; */
    position: relative;
    
    overflow-x: auto;
}
.boss{
    width: 100%;
    height: calc(100% - 46px);
    position: absolute;
    /* background-color: rebeccapurple; */
    top: 46px;
}
